/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap";
import { jsx } from '@emotion/react'
import LogoSerios from '../assets/images/lp/logotipo_serios.svg'

import { 
  sucessContent,
  logoImg, 
}  from '../assets/styles/LP.styles'

import { 
    content
  }  from '../assets/styles/EventoEnem'

const ContentSection = () => {
  return (
    <section css={sucessContent}>
      <Container>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <div css={logoImg}><img src={LogoSerios} alt="Colégio Seriös" title="Colégio Seriös" /></div>
            <div className="content-box">
              <h1>REGULAMENTO DE BOLSAS ESCOLARES</h1>
              <h3 style={{ textAlign: 'center' }}>Colégio Seriös</h3>
              <h3 style={{ marginTop: 40 }}>INTRODUÇÃO</h3>
              <p>O compromisso com a excelência educacional e o desenvolvimento integral dos alunos é um pilar fundamental do Colégio Seriös. Reconhecendo a diversidade de talentos, necessidades e aspirações dos estudantes, este regulamento visa estabelecer as diretrizes para a concessão de bolsas escolares, desenhadas para apoiar e incentivar alunos excepcionais em várias áreas. Seja por mérito acadêmico, habilidades esportivas, potencial artístico ou necessidades financeiras, estas bolsas visam facilitar o acesso a uma educação de qualidade e promover um ambiente escolar inclusivo e estimulante.</p>
              <p>Este documento apresenta os critérios de elegibilidade para cada tipo de bolsa, assim como as obrigações gerais e específicas que os bolsistas devem cumprir para manter seu benefício. Estabelecemos um Comitê de Bolsas, que contará com a participação multidisciplinar da diretoria da escola, para realizar a análise do ponto de vista pedagógico, financeiro e da comunidade escolar. Ao definir estas normas, almejamos não só reconhecer o mérito e a dedicação dos nossos alunos, mas também fomentar uma comunidade escolar que valoriza o respeito, a responsabilidade e a excelência. Convidamos os estudantes e suas famílias a se familiarizarem com as oportunidades oferecidas e participarem deste programa que celebra e apoia o potencial único de cada aluno.</p>
              <h3 style={{ marginTop: 40 }}>OBJETO DA POLÍTICA DE BOLSAS</h3>
              <p>Este regulamento estabelece critérios e padronizam normas, condições, prazos e obrigações a serem observados para a concessão dos benefícios propostos. O presente regulamento se aplica para a unidade do Colégio Seriös localizado na: SGAS 902, Lote 75, Conj. A, Asa Sul, Brasília-DF.</p>
              <p>As bolsas concedidas pelo Colégio Seriös caracterizam-se como contratos, nos quais o aluno e seus responsáveis financeiros recebem o incentivo, comprometendo-se com contrapartidas específicas para cada modalidade de bolsa. Cada uma das modalidades de bolsa do Colégio Seriös abrange uma contrapartida por parte do estudante durante a vigência de seu contrato. A falta de cumprimento das contrapartidas especificas de cada modalidade de bolsa pode acarretar a suspensão da bolsa por parte da escola, unilateralmente.</p>
              <h3 style={{ marginTop: 40 }}>DESCRIÇÃO E ELIGIBILIDADE PARA CADA BOLSA</h3>
              <p><span>• Bolsa Acadêmica</span><br />
              Bolsa destinada a alunos com destaque acadêmico. Os candidatos a esta modalidade prestam uma avaliação acadêmica, elaborada pelo próprio Colégio Seriös, para ranqueamento do seu resultado e devida concessão da bolsa, ou comprovam excelência acadêmica através de: medalhas olímpicas na OBMEP, OBF e OBQ, participação como finalista na Olimpíada Sapientia ou medalha de ouro nas olimpíadas de Astronomia, Biologia, Geografia, História ou Informática. Outras medalhas olímpicas serão avaliadas pelo Colégio Seriös. Caso o aluno já tenha demonstrado o destaque no próprio colégio, fica a critério do Colégio Seriös a dispensa da avaliação acadêmica.</p>
              <p><span>• Bolsa Artística</span><br />
              Bolsa destinada a alunos com destaque nas áreas artísticas e culturais valorizadas pela escola, como Música, Teatro, Artes Visuais ou outras modalidades que poderão entrar no campo praticado pelo Colégio Seriös. Os estudantes que possuem destaque em áreas culturais serão avaliados pela escola para esta modalidade. Para avaliação da escola, devem ser apresentadas: uma carta de recomendação assinada por profissional credenciado e um currículo sucinto do profissional assinando a carta de recomendação. Ficará a critério do Comitê de Bolsas avaliar a carta e currículo apresentados para concessão da bolsa específica.</p>
              <p><span>• Bolsa Atleta</span><br />
              Bolsa destinada a alunos com destaque esportivo. O aluno que pode se qualificar nessa modalidade deve ter seu registro na Federação do seu esporte e apresentar os méritos esportivos em sua categoria, que serão avaliados pelo Colégio Seriös.</p>
              <p><span>• Bolsa Financeira</span><br />
              Bolsa destinada a famílias em situação de vulnerabilidade social e financeira. O colégio outorga-se o direito de solicitar documentos que comprovem as necessidades socioeconômicas do candidato para avaliar a concessão de bolsa, como: (i) carta de solicitação de bolsa explicando as necessidades específicas, as motivações para estudar na escola, bem como os compromissos pessoais com as rotinas de estudo, e (ii) comprovação de renda compatível com a solicitação. A escola poderá solicitar o encaminhamento de IR dos responsáveis.</p>
              <h3 style={{ marginTop: 40 }}>REQUISITOS E CONTRAPARTIDAS ESPECÍFICOS</h3>
              <p><span>• Bolsa Acadêmica</span><br />
              - Participar ativamente de projetos acadêmicos extracurriculares, como olimpíadas de conhecimento, feiras de ciências, vestibulares ou concursos indicados pela escola.<br />
              - Alcançar uma média geral mínima de 9,0 (ou outro critério de excelência acadêmica) em todas as disciplinas.<br />
              - Cessão de imagem para peças publicitárias do Colégio Seriös.</p>
              <p><span>• Bolsa Artística</span><br />
              - Demonstrar talento artístico reconhecido através de apresentações, exposições ou performances em eventos internos e externos.<br />
              - Participar de forma ativa em atividades culturais da escola.<br />
              - Alcançar uma média geral mínima de 7,0 (ou outro critério de excelência acadêmica) em todas as disciplinas.<br />
              - Submeter um portfólio ou currículo artístico (caso aplicável) para avaliação.<br />
              - Cessão de imagem para peças publicitárias do colégio, bem como divulgação e apoio ao Colégio Seriös em postagens em rede social.</p>
              <p><span>• Bolsa Atleta</span><br />
              - Participar ativamente de uma equipe esportiva oficial da escola ou de competições externas, representando o Colégio Seriös.<br />
              - Participar de eventos internos de motivação e integração com os demais alunos como exemplo: campeonatos esportivos internos; feiras de ciências e eventos culturais;<br />
              - Apresentar comprovação de bom desempenho esportivo em campeonatos locais, regionais, estaduais ou nacionais.<br />
              - Manter uma média geral mínima de 7,0 (ou outro critério estabelecido) nas disciplinas escolares.<br />
              - Cessão de imagem para peças publicitárias do colégio, bem como divulgação e apoio ao Colégio Seriös em postagens em rede social.</p>
              <p><span>• Bolsa Financeira</span><br />
              - Apresentar documentação que comprove a situação financeira, como Declaração de Imposto de Renda, contracheques, ou declaração de vulnerabilidade emitida por assistente social.<br />
              - Manter uma média geral mínima de 7,0 (ou outro critério estabelecido) nas disciplinas escolares.<br />
              - Demonstrar bom comportamento e participação nas atividades escolares.<br />
              - Renovação sujeita à reavaliação anual da condição financeira.</p>
              <h3 style={{ marginTop: 40 }}>OUTRAS DISPOSIÇÕES</h3>
              <p><span>Requisitos Gerais.</span> Além dos requisitos específicos de cada tipo de bolsa, o Aluno bolsista e o seu Responsável Financeiro deverão: (i) manter frequência mínima de 85% (oitenta e cinco por cento) de presença em qualquer disciplina; (ii) não ficar inadimplente por mais de 60 (sessenta) dias, sendo estes consecutivos ou não; (iii) não apresentar em seu currículo escolar qualquer anotação de indisciplina caracterizada como grave perante o Regimento Escolar ou a Política Pedagógica do Colégio Seriös.</p>
              <p><span>Assinatura do Termo de Bolsa.</span> A concessão do benefício da bolsa de estudos aos alunados contemplados, requer assinatura pelo responsável legal do aluno, do Contrato de Prestação de Serviços Educacionais do ano letivo vigente, no qual constará o valor da bolsa, bem como as regras para manutenção ou suspensão do benefício, bem como a adesão a este Regulamento.</p>
              <p><span>Duração e Renovação.</span> As bolsas são válidas por um ano letivo, sendo que o Comitê de Bolsas será responsável por julgar a manutenção ou não da Bolsa para o ano letivo seguinte, sendo reavaliada ano a ano.</p>
              <p><span>Aplicabilidade.</span> As bolsas que venham a ser concedidos, como definido acima, incidirão apenas sobre o preço dos serviços educacionais propriamente ditos, não se estendendo, portanto, ao material didático, conteúdo digital, serviços digitais, serviços adicionais, extraordinários e/ou administrativos, incluindo cursos e atividades extracurriculares</p>
              <p><span>Revogação.</span> O não cumprimento de qualquer dos requisitos estabelecidos acima, tanto os gerais quanto os específicos, pode resultar na perda imediata da bolsa, inclusive durante o ano letivo. </p>
              <p><span>Documentação Adicional.</span> O Colégio Seriös poderá solicitar, a qualquer tempo dos responsáveis pelos alunos, documentação adicional que comprove o atendimento aos critérios especificados neste Termo, seja para concessão do benefício, seja para manutenção.</p>
              <p><span>Processo de Candidatura.</span> Cada bolsa terá seu próprio processo seletivo, incluindo a submissão de documentos, entrevistas e avaliação por uma comissão da escola. </p>
              <p><span>Percentual das Bolsas.</span> Cada bolsa terá seu critério e tamanho vinculado, sendo certo que isso poderá ser revisto a qualquer tempo pelo Comitê de Bolsas do Colégio Seriös. Inicialmente, as bolsas poderão atingir de 20% a 100%, distribuídas uniformemente, conforme disponibilidade.</p>
              <p><span>Descontos Comerciais.</span> Os descontos comerciais disponíveis no Colégio Seriös, tais como pontualidade, descontos por irmãos, parcerias, entre outros, não serão cumulativos com as bolsas educacionais estabelecidas neste Regulamento.</p>
              <p><span>Política consistente.</span> É fundamental manter um regramento consistente e aplicá-la de forma justa e equitativa a todos os alunos e responsáveis financeiros. Isso inclui seguir os prazos e regras estabelecidos nesta Política, bem como aplicar eventuais penalidades de forma consistente, para a manutenção da equidade pretendida.</p>
              <p><span>Comunicação Clara e Objetiva.</span> A comunicação com os responsáveis financeiros deve ser clara e objetiva, explicando os procedimentos de bolsa, os prazos, as consequências da sua concessão, nos termos desta Política e do Contrato de Prestação de Serviços Escolares.</p>
              <p><span>Casos Anteriores.</span> No caso de tratamento de Bolsas concedidas anteriormente a aprovação desta política, os mesmos procedimentos deverão ser adotados, tomando como base o processo de submeter à aprovação da bolsa para o Comitê de Bolsas para o ano letivo de 2025.</p>
              <p><span>Alterações na Política de Cobrança.</span> O Comitê de Bolsas do Colégio Seriös se reserva o direito de alterar esta política de cobrança a qualquer momento, mediante notificação prévia aos responsáveis.</p>
              <p><span>Casos Omissos ou Dúvidas.</span> Caso existam condições a serem aplicadas nos casos concretos que não estejam previstas nesta política, o Diretor Financeiro do Colégio Seriös ou de sua mantenedora deverá decidir de forma soberana.</p>
              <p style={{ textAlign: 'center' }}><span>Este regulamento entra em vigor em 20 de outubro de 2024.</span></p>
              <a href="/formulario-bolsa">Acesse o Formulário de bolsas escolares</a>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ContentSection;
